<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form @submit="addBinCode" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Bank Code:"
                label-for="input-1"
                description="Ví dụ: Ngân hàng VPBank thì nhập là VPB, Vietcombank thì nhập VCB..."
              >
                <b-form-input
                  id="input-1"
                  v-model="form.bank_code"
                  required
                  placeholder="Nhập bank code"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Bin Code:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.bin"
                  required
                  type="number"
                  placeholder="Nhập 6 số đầu của thẻ"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Loại thẻ:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.card_type"
                  :options="card_type_option"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                label="Thương hiệu thẻ:"
                label-for="input-4"
              >
                <b-form-select
                  id="input-4"
                  v-model="form.card_brand"
                  :options="card_brand_option"
                ></b-form-select>
              </b-form-group>

              <b-button type="submit" variant="primary">Xác nhận</b-button>
              <b-button type="reset" variant="danger">Làm mới</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const BankRepository = RepositoryFactory.get("bank");

export default {
  mixins: [Common],
  data() {
    return {
      form: {
        bank_code: "",
        bin: "",
        card_type: null,
        card_brand: null,
      },
      card_type_option: [
        { text: "Chọn loại thẻ", value: null },
        "ATM",
        "ATM_OLD",
        "CREDIT",
        "DEBIT",
        "PREPAID",
      ],
      card_brand_option: [
        { text: "Chọn thương hiệu", value: null },
        "VISA",
        "MASTERCARD",
        "JCB",
        "AMERICAN EXPRESS",
        "UNION PAY",
      ],
      show: true,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Công cụ", route: "tools" },
      { title: "Thêm thẻ phát hành tại VN", route: "add-bin-code" },
    ]);
  },
  methods: {
    addBinCode: async function () {
      if (confirm("Xác nhận thêm đầu thẻ phát hành tại Việt Nam?")) {
        this.$bus.$emit("show-loading", true);
        BankRepository.addBinBankCode(
          this.form.bank_code,
          this.form.bin,
          this.form.card_type,
          this.form.card_brand
        )
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              alert(response.data.message);
            } else {
              alert(response.data.message);
              window.location.reload();
            }
          })
          .catch(() => {
            this.$bus.$emit("show-loading", false);
            alert("Có lỗi xảy ra");
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form.bank_code = "";
      this.form.bin = "";
      this.form.card_type = null;
      this.form.card_brand = null;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
